import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDDsuWu3rc7YNqHkunC7BHlrrqoL5x_zo",
  authDomain: "spartahack8.firebaseapp.com",
  projectId: "spartahack8",
  storageBucket: "spartahack8.appspot.com",
  messagingSenderId: "416271478137",
  appId: "1:416271478137:web:51eaef22f82603910d93a8",
  measurementId: "G-0271Q48L7M"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export {app, storage};